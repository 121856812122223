import React from 'react';

import './estilo.css';
import Topo from '../../componentes/Topo';
import Rodape from '../../componentes/Rodape';

import Telefone from '../../assets/telefone.svg'
import Email from '../../assets/mail.svg'
import Vanessa from '../../assets/mulher.svg'

const PaginaProdutos = () => (
  <div>
    <Topo />
    <main>

      {/* SEÇÃO CAPA PRODUTOS */}
      <section className="secao-capa-produtos">
        <div className="titulo-produtos limita-largura">
          <h1>CONTATO</h1>
        </div>
      </section>

      {/* SEÇÃO NOSSOS PRODUTOS */}
      <section className="secao-produtos">
        <div className="limita-largura">
            <div className='subtitulo'><h2><span>Entre</span> em contato conosco</h2>
            </div>

            <div className='contatos'>
              <div className='pessoa'>
                <div className='telefone'>
                  <img alt='logotipo pessoa' src={Vanessa}/>
                  <p>Vanessa Gonzalez</p>
                </div>
                <div className='telefone'>
                  <img alt='logotipo telefone' src={Telefone}/>
                  <p>(85) 9 9695-2292</p>
                </div>
                <div className='telefone'>
                  <img alt='logotipo email' src={Email}/>
                  <p>vanessa@protecao360.com</p>
                </div>
              </div>

            </div>
        </div>
      </section>
    </main>
    <Rodape />
  </div>
);

export default PaginaProdutos;
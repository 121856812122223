import React from 'react';
import './estilo.css';

import Topo from '../../componentes/Topo';
import Rodape from '../../componentes/Rodape';



const PaginaHome = () => (
  <div>
    <Topo />
    <main>
      {/* SEÇÃO CAPA */}
      <section className="secao-capa">
        <div className="titulo limita-largura">
          <h1>Inovação,</h1>
          <h1>Qualificação &</h1>
          <h1>Corretores em sintonia.</h1>
        </div>
      </section>

      {/* NOSSOS DIFERENCIAIS */}
      <section className="secao-diferenciais">

        <div className="limita-largura">
          <div className='subtitulo'><h2><span>Objetivos</span></h2></div>

          <div className='objetivos'>
            <div className="diferenciais-01">
              <div className='objetivo'>
                <h1 className='titulo-objetivo'>01</h1>
                <p>Espaço projetado sob medida para profissionais de seguros, ofertando um ambiente sólido e acolhedor onde o corretor poderá prosperar através de um coworking que respira Proteção.</p>
              </div>
              <div className='objetivo'>
                <h1 className='titulo-objetivo'>02</h1>
                <p>Foco incansável em treinamento, desenvolvimento, comprometimento em elevar habilidades e conhecimentos nas áreas de Acumulação, Proteção e Renda.</p>
              </div>
              <div className='objetivo'>
                <h1 className='titulo-objetivo'>03</h1>
                <p>Serão desenvolvidos no "Centro de Excelência para Profissionais de Seguros“ em parceria com as  Seguradoras, programas de treinamento especializados, ministrados por especialistas do setor, com o objetivo contínuo de manter os consultores atualizados com as últimas tendências e práticas de mercado.</p>
              </div>
              <div className='objetivo'>
                <h1 className='titulo-objetivo'>04</h1>
                <p>Aqui, o Corretor respirará o mundo da Proteção Pessoal e Patrimonial todos os dias, adquirindo a educação necessária para se destacar na abordagem de vendas e no atendimento ao cliente.</p>
              </div>
            </div>
            <div className='diferenciais-02'>
              <div className='objetivo'>
                <h1 className='titulo-objetivo'>05</h1>
                <p>Nossa infraestrutura física será pensada para oferecer o melhor ambiente de trabalho, com instalações de última geração e tecnologia de ponta para apoiar suas operações, inclusive CRM para gestão própria das vendas e pós-vendas.</p>
              </div>
              <div className='objetivo'>
                <h1 className='titulo-objetivo'>06</h1>
                <p>Disponibilizaremos a possibilidade de usar nosso endereço como endereço fiscal para registro da Corretora.</p>
              </div>
              <div className='objetivo'>
                <h1 className='titulo-objetivo'>07</h1>
                <p>Além disso, forneceremos suporte profissional para ajudá-los a alcançar seus objetivos através do expertise do nosso CEO Fernando Dantas que conta com 30 anos de mercado além da experiência em formação de consultores de vendas por 5 anos em seguradora e 7 anos como professor da ENS. </p>
              </div>
            </div>
          </div>

        </div>
      </section>

      {/* SEÇÃO MISSÃO, VISÃO, VALORES */}
      <section className="secao-missao">

        <div className="limita-largura">

          <div className='subtitulo'><h2><span>Faça</span> parte da comunidade</h2>
          </div>

          <div>
            <div className='faca-parte'>
              <img alt='logotipo check' src={require('../../assets/check.png')}/>
              <p>Descubra a diferença em nosso Centro de Excelência para Profissionais de Seguros</p>
            </div>

            <div className='faca-parte'>
              <img alt='logotipo check' src={require('../../assets/check.png')}/>
              <p>Comunidade de colegas apaixonados pelo setor de seguros, compartilhando experiências, fazendo brainstorm, networking e explorando oportunidades de colaboração</p>
            </div>

            <div className='faca-parte'>
              <img alt='logotipo check' src={require('../../assets/check.png')}/>
              <p>Espaço onde o potencial de cada um pode ser desbloqueado, onde a educação e o desenvolvimento serão prioridades, e onde a Acumulação, Proteção e Renda serão mais do que palavras, seu lugar é aqui</p>
            </div>
          </div>
        </div>
      </section>

      {/* SEÇÃO PARCEIROS */}
      <section className='secao-parceiros'>
        <div className='limita-largura'>
          {/* <div className='subtitulo'><h2><span>Nossos</span> parceiros</h2>
          </div> */}

          <div className='todos-parceiros'>
            <img alt='Imagem Icatu Seguros' src={require('../../assets/icatu.png')} />
            
          </div>

        </div>
      </section>

      {/* SEÇÃO ENDEREÇO */}
      <section className="secao-endereco limita-largura">
        <div className="subtitulo">
          <h2><span>Como</span> Chegar?</h2>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.352557173851!2d-38.5015281243899!3d-3.733111396240813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c74863b6aed93b%3A0x50bd7cbc9358b310!2sAv.%20Dom%20Lu%C3%ADs%2C%20300%20-%20Aldeota%2C%20Fortaleza%20-%20CE%2C%2060160-230!5e0!3m2!1spt-BR!2sbr!4v1681869456331!5m2!1spt-BR!2sbr" width="100%" title='pin' height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </section>

    </main>
    <Rodape />
  </div>
);

export default PaginaHome;